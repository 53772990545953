import React from 'react';
import { graphql } from 'gatsby';
import { Box, Heading, Image } from 'theme-ui';
import { FaClock, FaCalendarDay } from 'react-icons/fa';
import slugify from '../helpers/slugify';
import OtherBlogs from '../components/UI/OtherBlogs';
import OtherGalleries from '../components/UI/OtherGalleries';

export default function BlogTemplate({ data }) {
  const {
    id,
    blogTitle,
    // categories,
    blogDescription,
    blogContent,
    // updatedAt,
    publishedDate,
    blogMainImage,
  } = data.contentfulBlogPosts;

  return (
    <Box sx={styles.container}>
      <Box sx={styles.blogImageContainer}>
        <Image sx={styles.blogImage} src={blogMainImage.file.url} />
      </Box>

      <Box sx={styles.row}>
        <Box sx={styles.blogColumn}>
          <Box sx={styles.heading}>
            <Heading as='h1' sx={styles.blogTitle}>
              {blogTitle}
            </Heading>

            <Heading
              as='h2'
              sx={styles.blogDescription}
              dangerouslySetInnerHTML={{
                __html: blogDescription.childMarkdownRemark.html,
              }}
            />

            <Box sx={styles.details}>
              <Box sx={styles.detail}>
                <FaClock />{' '}
                {`${blogContent.childMarkdownRemark.timeToRead} min read`}
              </Box>
              <Box sx={styles.detail}>
                <FaCalendarDay /> {`${publishedDate}`}
              </Box>
            </Box>
          </Box>

          <Box
            sx={styles.blogContent}
            dangerouslySetInnerHTML={{
              __html: blogContent.childMarkdownRemark.html,
            }}
          />
        </Box>
        <Box sx={styles.sideBar}>
          <OtherBlogs currentBlogID={id} />
          <OtherGalleries />
        </Box>
      </Box>
    </Box>
  );
}
const styles = {
  container: {
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: ['column', '', 'row'],
    width: '100%',
    margin: '0 auto',
    padding: ['1rem 1rem', '', '1rem 1rem 1rem calc(3% - 0.5rem)'],
  },
  blogColumn: {
    width: ['100%', '', '65%', '70%', '70%'],
    padding: ['0rem', '', '0rem 1rem 1rem 1.5rem'],
    position: 'relative',
    top: ['', '', '-27vh'],
  },
  sideBar: {
    width: ['100%', '', '35%', '30%', '30%'],
    padding: '1rem',
  },

  articleBody: {},
  blogImageContainer: {
    height: '100vh',
    clipPath: [
      '',
      '',
      'polygon(0px 0px, 100% 0px, 100% 100%, 70% 100%, 53% 70%, 0px 70%)',
    ],
  },
  blogImage: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },

  heading: {
    borderLeft: '5px solid',
    borderColor: 'primary',
    padding: '0rem 0rem 0rem 2rem',
  },

  blogTitle: {
    width: ['100%', '', '80%'],
    fontSize: ['1.75rem', '2rem', '2.5rem'],
  },
  blogDescription: {
    width: ['100%', '', '80%'],
    fontSize: ['1rem', '1.25rem', '1.5rem'],
    opacity: '0.7',
  },
  details: {
    display: 'flex',
  },
  detail: {
    margin: '0.5rem 1rem 0.5rem 0rem',
  },
  blogContent: {
    padding: '1rem 1rem 1rem 0rem',

    'h1, h2, h3, h4, h5, h6': {
      color: 'primary',
    },

    a: {
      color: 'primary',
      cursor: 'pointer',
    },

    img: {
      width: '100%',
    },
    p: {
      margin: '0rem',
      marginBottom: '1rem',
    },
    pre: {
      width: '100%',
      background: '#464040',
    },
    code: {
      background: '#464040',
      color: 'white',
      padding: '1rem 2rem',
      display: 'flex',
      fontSize: '0.85rem',
    },

    blockquote: {
      '::before': {
        content: '"“"',
        fontSize: '2.5rem',
        padding: '0rem 1rem',
        lineHeight: '0.75',
      },
      display: 'flex',
      alignItems: 'flex-start',
      backgroundColor: '#f7f7f7',
      padding: ['2rem 1rem', '2rem'],
      margin: '0rem',
      borderLeft: 'solid 6px',
      borderColor: 'secondary',
      color: '#5e5d5a',
      p: {
        marginBottom: '0rem',
      },
    },
  },
};

export const query = graphql`
  query SingleBlogPost($id: String) {
    contentfulBlogPosts(id: { eq: $id }) {
      id
      categories
      blogTitle
      blogMainImage {
        file {
          url
        }
      }
      blogDescription {
        childMarkdownRemark {
          html
        }
      }
      blogContent {
        childMarkdownRemark {
          html
          timeToRead
        }
      }
      updatedAt(formatString: "DD MMM yyyy")
      publishedDate(formatString: "DD MMM yyyy")
      createdAt(formatString: "DD MMM yyyy")
    }
  }
`;

import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Box, Heading, Image } from 'theme-ui'

import { FaImages, FaCalendarDay } from 'react-icons/fa'
import slugify from '../../helpers/slugify'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

export default function OtherGalleries({ currentGalleryID }) {
  const data = useStaticQuery(graphql`
    query OtherGalleriesQuery {
      allContentfulGallery(filter: { node_locale: { eq: "en-US" } }) {
        nodes {
          id
          albumCover {
            file {
              url
            }
            description
          }
          albumName
          albumSubtitle
          description {
            childrenMarkdownRemark {
              html
            }
          }
          images {
            file {
              url
            }
            description
          }
          location {
            lat
            lon
          }
          updatedAt(formatString: "DD MMM, YYYY")
        }
      }
    }
  `)
  // filter out the current blog
  const galleryData = data.allContentfulGallery.nodes.filter(
    (gallery) => gallery.id !== currentGalleryID
  )

  return (
    <Box sx={styles.container}>
      <Heading as={'h5'}>Galleries</Heading>
      {galleryData.map((gallery) => {
        const { albumCover, images, albumName, updatedAt } = gallery
        return (
          <AniLink
            to={`/gallery/${slugify(albumName)}`}
            rel='noopener'
            paintDrip
            duration={0}
            hex='#e01f5a'
          >
            <Box sx={styles.gallery}>
              <Box sx={styles.imageContainer}>
                <Image
                  sx={styles.image}
                  src={albumCover.file.url}
                  alt={albumName}
                />
              </Box>

              <Box sx={styles.content}>
                <Heading as='h4' sx={styles.albumTitle}>
                  {albumName}
                </Heading>
                <Box sx={styles.detail}>
                  <FaImages /> {`${images.length} Images`}
                </Box>
                <Box sx={styles.detail}>
                  <FaCalendarDay /> {`${updatedAt}`}
                </Box>
              </Box>
            </Box>
          </AniLink>
        )
      })}
    </Box>
  )
}

const styles = {
  container: {
    width: '100%',
    h5: {
      color: 'primary',
      borderBottom: '2px solid',
      padding: '0.5rem 0rem',
      textTransform: 'uppercase',
      marginBottom: '0.25rem',
    },
  },
  gallery: {
    width: '100%',
    display: 'flex',
    padding: '0.5rem 0rem',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  content: {
    width: '70%',
    padding: '0.5rem',
    color: 'text',
  },
  imageContainer: {
    width: '30%',
    maxHeight: '100px',
  },

  albumTitle: {
    fontSize: '0.9rem',
  },
  detail: {
    fontSize: '0.75rem',
  },
}

import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Box, Heading, Image } from 'theme-ui'
import { FaClock, FaCalendarDay } from 'react-icons/fa'
import slugify from '../../helpers/slugify'
import AniLink from 'gatsby-plugin-transition-link/AniLink'

export default function OtherBlogs({ currentBlogID }) {
  const data = useStaticQuery(graphql`
    query OtherBlogsQuery {
      allContentfulBlogPosts(filter: { node_locale: { eq: "en-US" } }) {
        nodes {
          id
          blogTitle
          categories
          blogDescription {
            childMarkdownRemark {
              html
            }
          }
          blogContent {
            childMarkdownRemark {
              html
              timeToRead
            }
          }
          updatedAt
          publishedDate(formatString: "DD MMM, YYYY")
          blogMainImage {
            file {
              url
            }
          }
        }
      }
    }
  `)

  // filter out the current blog
  const blogData = data.allContentfulBlogPosts.nodes.filter(
    (blog) => blog.id !== currentBlogID
  )

  return (
    <Box sx={styles.container}>
      <Heading as={'h5'}>Other Blogs</Heading>
      {blogData.map((blog) => {
        const {
          id,
          blogTitle,
          categories,
          blogDescription,
          blogContent,
          updatedAt,
          publishedDate,
          blogMainImage,
        } = blog
        return (
          <AniLink
            to={`/blog/${slugify(blogTitle)}`}
            rel='noopener'
            paintDrip
            duration={0}
            hex='#e01f5a'
          >
            <Box sx={styles.blog}>
              <Box sx={styles.blogImageContainer}>
                <Image
                  sx={styles.blogImage}
                  src={blogMainImage.file.url}
                  alt={blogTitle}
                />
              </Box>

              <Box sx={styles.content}>
                <Heading as='h4' sx={styles.blogTitle}>
                  {blogTitle}
                </Heading>
                <Box sx={styles.detail}>
                  <FaClock />{' '}
                  {`${blogContent.childMarkdownRemark.timeToRead} min read`}
                </Box>
                <Box sx={styles.detail}>
                  <FaCalendarDay /> {`${publishedDate}`}
                </Box>
              </Box>
            </Box>
          </AniLink>
        )
      })}
    </Box>
  )
}

const styles = {
  container: {
    width: '100%',
    h5: {
      color: 'primary',
      borderBottom: '2px solid',
      padding: '0.5rem 0rem',
      textTransform: 'uppercase',
      marginBottom: '0.25rem',
    },
  },
  blog: {
    width: '100%',
    display: 'flex',
    padding: '0.5rem 0rem',
  },
  blogImageContainer: {
    width: '30%',
    maxHeight: '100px',
  },
  blogImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  content: {
    width: '70%',
    padding: '0.5rem',
    color: 'text',
  },

  blogTitle: {
    fontSize: '0.9rem',
  },
  detail: {
    fontSize: '0.75rem',
  },
}
